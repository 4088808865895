<template>
  <svg
    class="map country el salvador"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 81.6 72.7"
  >
    <g class="hover">
      <path
        d="M19.6,16.9H17.9l1.3-7.2.7-5.1.1-1,.7-.3.9.8L24.1.2,25.2.1l-.1,1h1l-.3,1.8L24.5,5.6l.4,1L24,8.9l.3.6-1,3.3L22,14.5l-1.1.2-1.3,2.2Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        class="costa rica"
        d="M46.4,48.7l1.2,3.5,2,2.6,2.5,2.7-2.2.6-.1,2.6,1.1.9-.9.8.2,1.1-.6,1.3-.3,1.3-3-1.4-1.1-1.4.7-1.1-.1-1.4-1.5-1.5L42.1,58l-1.8-.8L40,55.3l-1.4-1.1.2,1.8-1.2,1.6-1.2-1.8-1.7-.7L34,53.9l.1-2,.9-2L33.5,49l1.4-1.3.9-.8,3.6,1.7,1.3-.8,1.8.5.8,1.3,1.7.5,1.4-1.4Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M19.9,4.6l-.7,5.1-1.3,7.2h1.7l1.7,1.2.6-1,1.5.8-2.8,2.5-2.9,1.8-.5,1.2.3,1.3-1.3,1.6-1.4.4.3.8-1.2.7-2,1.6-.3.9L8.8,29.6l-3.5-.1L2.9,28.2.1,25.6l.4-1.9.8-1.5L.6,21l3.3-5.2h7.2l.4-2.2-.8-.4-.5-1.4L8.3,10.3,6.5,8.2,9,8.1l.5-3.6h5.2l5.2.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M51.4,22.6l-1.9-.1-.9.9-2,.8H45.2l-1.3.8-1.1-.2-.9-1-.6.2-.9,1.5-.5-.1-.2,1.4-2.1,1.7-1.2.8-.6.8-1.5-1.3-1.4,1.7H31.7l-1.3.1-.2,3.2h-.8l-.8,1.5-1.8.3-.8-2L24.3,33l.7-2.6-.7-.7-1.2-.4-2.5.7-.1-.8-1.6-1.1-1.1-1.2-1.6-.6,1.3-1.6-.3-1.3.5-1.2,2.9-1.8,2.8-2.5.6.3,1.3-1.1,1.6-.1.5.5.9-.3,2.6.6,2.6-.2,1.8-.7.8-.7,1.7.3,1.3.4,1.5-.1,1.2-.6,2.5.9.8.2,1.6,1.2,1.5,1.4,1.9,1,1.3,1.7Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M51.4,22.6l-.5.7-.5,1.4.4,2.3-1.5,2.2-.8,2.6L48,34.6l.2,1.7-.1,2.9-.9.6-.7,2.8.2,1.7-1.2,1.6.1,1.7.8,1.1L45,50.1l-1.7-.5-.8-1.3-1.8-.5-1.3.8-3.6-1.7-.9.8-1.8-2-2.5-2.6L29.5,41l-2.2-2.1L24.8,36l.7-1,.8,1,.5-.4,1.8-.3.8-1.5h.8l.2-3.2,1.3-.1h1.2l1.4-1.7,1.5,1.3.6-.8,1.2-.8,2.1-1.7.2-1.4.5.1.9-1.5.6-.2.9,1,1.1.2,1.3-.8h1.4l2-.8.9-.9,1.9.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M81.1,63.3l-.7.9L81.5,68l-1.1,1.9-1.8-.5-.9,3.1-1.8-1.8-1-3.5,1.4-1.7-1.4-.4L74,63l-2.8-1.8-2.4.4-1.3,2.2-2.4,1.6-1.2.2L63.3,67l2.5,3.5-1.6.8-.8.9-2.7.4-.8-3.9-.8,1.1-1.8-.4-1-2.5L54,66.4l-1.5-.7H50.1l-.2,1.4-.6-1,.3-1.3.6-1.3L50,62.4l.9-.8-1.1-.9.1-2.6,2.2-.6L54,59.8l-.2,1.4,2.2.3.6-.6,1.5,1.6,2.8-.5,2.5-1.6,3.5-1.3,2-1.9,3.1.4-.2.6,3.1.2,2.4,1.2,1.8,1.9,2,1.8Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        class="el salvador active"
        d="M24.3,33l-.7,1.5-3.3-.1-2-.6-2.2-1.3-3-.4-1.5-1.4.3-.9,2-1.6,1.2-.7-.3-.8,1.4-.4,1.6.6,1.1,1.2,1.6,1.1.1.8,2.5-.7,1.2.4.7.7L24.3,33Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
    </g>
    <g class="pin" transform="translate(14 19) scale(0.4)">
      <path
        fill="#000"
        d="M17.54,3.52A9.9,9.9,0,0,0,12,.21,9.91,9.91,0,0,0,.22,7.65,9.48,9.48,0,0,0,0,10.18c-.16,5.18,3.92,10,7.7,15,.93,1.15,2.23,2.42,2.29,2.57l2.08-2.46C16.82,19.08,23.74,10.05,17.54,3.52Zm-2.86,7.79A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <path
        fill="#fff"
        d="M11.55,2.93A7.37,7.37,0,0,0,2.82,8.77a7.6,7.6,0,0,0,5.67,9l.18,0a7.37,7.37,0,0,0,8.55-5.87A7.59,7.59,0,0,0,11.55,2.93Zm3.13,8.38A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
    </g>
  </svg>
</template>
